@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import "~react-image-gallery/styles/scss/image-gallery.scss";


$primary-color: #e227e2;
$secondary-color: greenyellow;
$headings: "Open Sans", Sans-serif;
$para-color: #d0cdcd;

$subheading: 'Shadows Into Light', cursive;

html {
    scroll-behavior: smooth;
}

body {
    background-color: black;
    color: white;
    font-family: 'Montserrat', 'sans-serif';
    overflow-x: hidden;
}
input {
    font-family: 'Montserrat', 'sans-serif';
}
a {
    text-decoration: none;
    color: white;
}

#twitter-icon {
    color: #00acee
}
#insta-icon {
    color: #C13584
}
#page-footer-text-sml {
    font-size: 0.6em;
    color: $primary-color;
    text-align: center;
    padding-bottom: 100px;
}
#error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
        color: $primary-color;
        font-family: $subheading;
        font-size: 3em;
    }
}
.loading-gif {
    height: 30px;
    width: 30px;
}

#back-top-btn {
    position: fixed;
    border: 2px solid $primary-color;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: $primary-color;
    font-size: 1.5em;
    cursor: pointer;
}
// ---------------------------------------------
// ------------- HEADER COMPONENT --------------
// ---------------------------------------------

#contact-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #header-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    #contact-icon {
        padding: 2vh 0;
    }
    span {
        color: $secondary-color;
        font-size: 1em;
    }
    #viper-logo {
        max-width: 80vw;
        cursor: pointer;
        height: auto;
    }
    i {
        font-size: 1em;
        margin-right: 5px;
        color: $primary-color;
    }
    #i-adj {
        margin-right: 10px;
    }
}

#landing-nav-bar {
    button {
        font-size: 1.2em;
        background-color: black;
        color: $secondary-color;
        padding: 15px 20px;
        font-weight: 700;
    }
    z-index: 999;
}


// ---------------------------------------------
// ------------- PAGE FOOTER -------------------
// ---------------------------------------------

#page-footer {
    padding: 50px 0;
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    border-top: 1px solid #555555;
    div {
        margin: 20px;
    }
    .footer-title {
        font-weight: 700;
        font-size: 1.3em;
    }
    #footer-left {
        min-width: 300px;
        img {
            width: 200px;
            height: auto;
            margin: 20px 0;
        }
    }
    #footer-links, #footer-contact, #footer-socials {
        display: flex;
        flex-direction: column;
        a {
            margin: 5px 0;
        }
        i {
            font-size: 2em;
        }
    }
    #footer-links {
        min-width: 150px;
    }
    #footer-socials {
        min-width: 150px;
    }
}


// ---------------------------------------------
// ------------- PHONE NAV BAR -----------------
// ---------------------------------------------

#nav-bar-phone {
    position: fixed;
    bottom: 0;
    // width: 100vw;
    // z-index: 999;
    background-color: black;
    button {
        width: 20vw;
        color: $secondary-color;
        i {
            color: $primary-color;
            font-size: 2em;
            padding-left: 2px;
        }
        span {
            font-size: 0.7em;
        }
        text-align: center;
        background-color: black;
    }
    border-top: 1px solid lightgrey;
}


// ---------------------------------------------
// ------------- LANDING PAGE ------------------
// ---------------------------------------------

#landing-page-container {
    width: 100vw;
    overflow-x: hidden;
    padding-bottom: 100px;
    img {
        width: 100vw;
    }

    #footer-graphic {
        position: relative;
        #back-img-footer {
            width: 100vw;
            height: auto;
            object-fit: cover;
        }
        #logo-footer {
            width: 100%;
            text-align: center;
            img {
                width: 70vw;
                height: auto
            }
            position: absolute;
            top: 40%;
        }
        margin-bottom: 50px;
    }

    #not-found-prompt {
        text-align: center;
        h3 {
            font-size: 3em;
            color: $primary-color;
            font-family: $subheading;
        }
        p {
            font-size: 1.5em;
            font-weight: 700;
            color: $secondary-color;
        }
    }
    #expore-heading {
        font-size: 2em;
        width: 100vw;
        text-align: center;
        color: $primary-color;
        font-weight: 700;
        margin: 0;
        font-family: $subheading
    }

    #landing-banner {
        position: relative;
        img {
            max-width: 100vw;
            height: 20vh;
            object-fit: cover;
            object-position: 5%; 
            opacity: 0.4;
        }
    
        h1 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 40%;
            // font-family: $subheading;
            font-size: 1.5em;
            font-weight: 400;
            color: white;
            span {
                font-weight: 700;
                color: $primary-color;
            }
                        
        }
    }
    #landing-div-2 {
        position: relative;
    }
    #legal-age-prompt {
        width: 100vw;
        color: white;
        position: absolute;
        bottom: 20px;
        text-align: center;
        font-weight: 700;
        text-shadow: 0 0 20px #fff, 
                    0 0 10px #fff, 
                    0 0 50px #FF00B0, 
                    0 0 50px #FF00B0, 
                    0 0 40px #FF00B0;
    }
    .content-text {
        padding: 15px;
        color: $para-color;
    }

    #option-bar-1 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10vh;
        .bar-option-card {
            margin: 10px 0;
            cursor: pointer;
            position: relative;
            img {
                border: 1px solid $primary-color;
            }
        }
    }
    .content-wrapper {
        margin: 5vh 0;
    }
    .content-sec {
        padding: 15px;
        .content-heading {
            font-family: $subheading;
            color: $primary-color;
            font-size: 2em;
            font-weight: 700;
        }
        .sub-heading {
            font-weight: 700;
            margin: 10px 0;
        }
        span {
            color: $para-color;
            line-height: 1.5;
        }
    }
    #todays-list {
        text-align: center;
        height: auto;
        .content-heading {
            font-size: 3em;
        }

        #scroll-nav {
            display: flex;
            width: 90vw;
            padding: 0 5vw;
            flex-direction: row;
            justify-content: space-between;
            button {
                background-color: $primary-color;
                color: white;
                padding: 10px 15px;
            }
        }
        
        button {
            background-color: black;
            color: $primary-color;
            border-radius: 30px;
            padding: 20px;
            
            margin: 20px 0;
            box-shadow: 0 0 3px #fff, 
            0 0 10px #fff, 
            0 0 50px #FF00B0;
        }
        #see-more-prompt {
            color: $primary-color;
            margin: 10px 0;
            span {
                color: $primary-color;
                font-size: 0.8em;
                font-weight: 600;
            }
            display: flex;
            justify-content: center;
            align-items: center;
        }
        #tl-display {
            background-color: transparentize($color: grey, $amount: 0.8);
            z-index: 2;
            padding: 10px 0;
            overflow-x: hidden;
            display: flex;
            flex-direction: row;
            .lady-card, .lady-card-hover {
                height:480px;
                span {
                    padding-top: 230px;
                }
                img {
                    width: 350px;
                    height:480px;
                }
            }
            #tl-list {
                display: flex;
                flex-direction: row;
                overflow-x: auto;
                padding-bottom: 10px;
                margin: 0 10px;
            }
            button {
                i {
                    font-size: 3em;
                }
                border: none;
                border-radius: 0;
                box-shadow: none;
                margin: 0;
                padding: 0;
                background-color: transparent;
            }
        
            .lady-card-hover {
                img {
                    opacity: 0.4;
                }
                span {
                    line-height: 1.3;
                }
            }
            p {
                text-align: center;
                width: 100vw;
                a {
                    font-weight: 700;
                    color: $primary-color;
                }
            }
        }
    }
    #background-slider-container {
        background-image: url("https://viper-images.s3-ap-southeast-2.amazonaws.com/by_window.jpg");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    #reason-div {
        text-align: center;
        h3 {
            font-size: 1.5em;
            padding: 50px 0;
            text-shadow: 0 0 20px #fff, 
                            0 0 10px #fff, 
                            0 0 50px #FF00B0, 
                            0 0 50px #FF00B0, 
                            0 0 40px #FF00B0;
        }
    
        .reason-card {
            display: flex;
            flex-direction: column;
            button {
                margin: 5px 0;
                padding: 20px 0;
                background-color: black;
                color: $primary-color;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                #down-arrow {
                    margin: 20px;
                }
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        margin: 0 20px;
                    }
                }
                i {
                    padding-bottom: 10px;
                    font-size: 3em;
                }
            }
            p {
                text-align: left;
                line-height: 1.5;
                font-weight: 500;
                padding: 50px 15px;
                color: $para-color;
                background-color: transparentize($color: black, $amount: .2)
            }
        }
        #call-btn {
            background-color: black;
            color: $primary-color;
            border-radius: 30px;
            padding: 15px 20px;
            margin: 20px 0;
        }
    }
    #testimony-div {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        p {
            margin: 0;
        }
        
        #test-wrapper {
            background-color: transparentize($color: black, $amount: .5);
            display: flex;
            flex-direction: row;
            overflow-x: auto;
        }
        div {
            min-width: 300px;
        }
        #main-test {
            background-color: transparentize($color: black, $amount: .5);
            font-family: $subheading;
            color: $primary-color;
            font-size: 2.2em;
            font-weight: 700;
            line-height: 1;
            padding: 50px;
        }
        .test-card {
            display: flex;
            flex-direction: column;
            span {
                font-weight: 600;
                color: $primary-color;
            }
            justify-content: center;
            align-items: center;
            font-style: italic;
            color: $para-color;
            padding: 20px 40px;
        }
    }
    #open-24 {
        font-weight: 600;
        padding: 50px 15px;
        font-size: 2em;
        text-align: center;
        text-shadow: 0 0 20px #fff, 
                        0 0 10px #fff, 
                        0 0 50px #FF00B0, 
                        0 0 50px #FF00B0, 
                        0 0 40px #FF00B0;
    }

    #blog-div {
        margin: 50px 0;
        h3 {
            font-family: $subheading;
            color: $primary-color;
            font-size: 2em;
            font-weight: 700;
            padding: 15px;
            background-color: transparentize($color: black, $amount: 0.4);
            margin: 0;
        }
        text-align: center;
        #prompt-text {
            padding: 15px;
            margin: 0;
            color: $para-color;
            background-color: transparentize($color: black, $amount: 0.4);
            font-weight: 700;
        }
        #blog-list {
            margin-top: 50px;
        }
        .blog-item {
            position: relative;
            width: 100vw;
            height: 250px;
            margin: 10px 0;
            cursor: pointer;
            img {
                width: 100vw;
                height: 250px;
                object-fit: cover;
            }
            p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                position: absolute;
                color: white;
                font-size: 2em;
                width: 100%;
                height: 100%;
                background-color: transparentize($color: black, $amount: 0.6);
                font-weight: 400;
                left: 0;
                top: 0;
                span {
                    font-size: 0.4em;
                    font-weight: 700;
                }
            }
        }
        button {
            margin: 50px 0;
            box-shadow: 0 0 3px #fff, 
            0 0 10px #fff, 
            0 0 50px #FF00B0;
            background-color: black;
            color: $primary-color;
            border-radius: 25px;
            padding: 15px;
        } 

        background-image: url("https://viper-images.s3-ap-southeast-2.amazonaws.com/EZEQ4010.jpg");
        background-attachment: fixed;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    #visit-div {
        width: 100vw;
        margin: 50px 0;
        text-align: center;
        .content-heading {
            font-family: $subheading;
            color: $primary-color;
            font-size: 2.5em;
            font-weight: 700;
        }
        div {
            // text-align: left;
            span {
                div {
                    width: 50px;
                }
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            i {
                font-size: 1.2em;
                color: $primary-color;
                padding-left: 20px;
            }
            color: $secondary-color;
        }
        a {
            color: $secondary-color;
        }
        #map-display {
            text-align: center;
            iframe {
                width: 90vw;
                height: 40vh;
            }
        }
    }
}

#profile-page-container {
    padding-bottom: 100px;
    #profile-name {
        width: 100vw;
        font-size: 3em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        #green-circle {
            margin-left: 20px;
            background-color: #1DB954;
            border-radius: 100%;
            width: 30px;
            height: 30px;
        }
    }
    #profile-desc {
        padding: 15px;
        color: $para-color;
        line-height: 1.8;
        text-align: center;
        span {
            font-size: 2em;
            color: $primary-color;
            font-family: $subheading;
            font-weight: 700;
        }
    }
    #photo-carosel {
        padding: 20px 0;
        border-top: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
    }
    #schedule-div {
        border-bottom: 1px solid lightgrey;
        text-align: center;
        #week-div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        padding-bottom: 50px;
        a {
            color: $primary-color;
            font-weight: 600;
        }
        button {
            margin: 20px;
            background-color: $primary-color;
            color: white;
            border-radius: 5px;
            font-weight: 700;
        }
        .day-card {
            width: 200px;
            margin-bottom: 20px;
            font-weight: 600;
            border-radius: 5px;
            font-size: 0.8em;
            div {
                margin: 0 20px;
                text-align: center;
                padding: 10px;
                font-size: 0.8em;
                &:first-child {
                    font-size: 0.9em;
                    background-color: $primary-color;
                    color: white;
                    border: 1px solid $primary-color;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                &:last-child {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                border: 1px solid white;
            }
        }
    }
}

#admin-page-container {
    padding: 5vh 5vw;
    min-height: 100vh;
    color: black;
    background-color: white;
    h3 {
        span {
            color: $primary-color
        }
        button {
            margin-left: 70vw;
            background-color: $primary-color;
        }
    }
    button {
        background-color: #1DB954;
        padding: 10px;
        color: white;
        font-weight: 700;
        border-radius: 5px;
    }
    #week-list {
        padding-top: 20px;
    }
    #week-list-wrapper {
        display: flex;
        flex-direction: column;
        div {
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            i {
                margin-left: 10px;
                color: #9c2121;
                cursor: pointer;
            }
            span {
                color: #1DB954;
                margin: 0 20px;
                cursor: pointer;
                font-weight: 700;
            }
            #confirm-btn {
                color: red;
                font-weight: 700;
                text-decoration: underline;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
    #login-input {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 150px;
        input {
            border: 1px solid $primary-color;
            padding: 15px 20px;
            width: 30vw;
        }
    }
}
#home-btn {
    background-color: #e227e2;
    color: white;
    font-weight: 700;
    padding: 10px;
    border-radius: 5px;
}

#employees-page-container {
    padding: 5vh 5vw;
    min-height: 90vh;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    #ep-left {
        padding-right: 50px;
        border-right: 1px solid $primary-color;
    }
    .form {
        input, textarea {
            width: 300px;
        }
    }
    #flash-msg {
        font-size: 0.8em;
        font-weight: 700;
    }
    #employee-list {
        padding: 5vh 5vw;
        #search-input {
            padding: 10px;
            width: 500px;
        }
        #list-container {
            display: flex;
            flex-wrap: wrap;
        }
        div {
            padding-top: 5px;
        }
        .employee-card {
            width: 300px;
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            overflow: hidden;
            justify-content: flex-start;
            align-items: center;
            img {
                width: 70px;
                height: 70px;
                border-radius: 100%;
                margin-right: 20px;
                object-fit: cover;
            }
            span {
                &:first-child {
                    font-weight: 600;
                    font-size: 1em;
                }
                font-size: 0.8em;
            }
        }
    }

    #upload-div {
        margin-left: 100px;
        .alt-text-input {
            margin-top: 10px;
        }
        input {
            padding: 10px;
            border: 1px solid lightgrey;
            margin-top: 10px;
            cursor: pointer;
        }
        button {
            background-color: #1DB954;
            border-radius: 5px;
            color: white;
            margin-top: 10px;
        }
        #media-list {
            border-top: 1px solid $primary-color;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            #alt-edit {
                font-size: 0.8em;
                font-weight: 600;
                color: #1DB954;
                cursor: pointer;
            }
            div {
                position: relative;
            }
            #photo-del-btn {
                position: absolute;
                top: 2px;
                right: -18px;
                font-size: 1.2em;
                cursor: pointer;
                background-color: white;
                border-radius: 100%;
            }
            img {
                width: 150px;
                height: 150px; 
                object-fit: cover;
                margin: 10px 10px 0 0;
                padding-bottom: 10px;
            }
        }
        #delete-btn {
            background-color: red;
            margin-top: 30vh;
            margin-right: 50px;
        }
        #delete-btn-confirm {
            background-color: #1DB954;
            margin-top: 30vh;
            margin-right: 50px;
        }
        #confirm-delete {
            color: red;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 700;
        }
        #unavailability-div {
            padding-bottom: 10px;
            border-bottom: 1px solid lightgrey;
            margin-bottom: 10px;
            #un-dates-list {
                display: flex;
                flex-wrap: wrap;
                p {
                    margin: 5px;
                    background-color: #f0f0f0;
                    border-radius: 10px;
                    padding: 5px 10px;
                    font-size: 0.8em;
                    font-weight: 600;
                }
            }
            #set-form {
                font-size: 0.8em;
            }
            a {
                margin-left: 10px;
                color: red;
                cursor: pointer;
                font-size: 0.9em;
                font-weight: 600;
            }
            input {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    #viewing-div {
        padding: 5vh 5vw;
        display: flex;
        
        i {
            font-size: 2em;
            color: $primary-color;
            cursor: pointer;
            margin-right: 20px;
        }
    }
}

#week-page-container {
    padding: 5vh 3vw;
    background-color: white;
    color: black;
    min-height: 100vh;
    #week-input-container {
        #hours-input {
            width: 100px;
        }
        #date-input {
            width: 200px;
        }
        #from-input {
            margin-right: 20px;
        }
        padding-bottom: 20px;
        #save-template-btn {
            margin-left: 20px;
        }
        #finish-btn {
            margin-left: 60%;
        }
        #template-input {
            width: 300px;
            margin-left: 10px;
        }
        select {
            font-family: 'Montserrat', 'sans-serif';
            padding: 9px;
            margin-left: 10px;
            width: 200px;
            border: 1px solid lightgrey;
        }
    }

    #week-display {
        padding-top: 20px;
        border-top: 1px solid lightgrey;
        display: flex;
        flex-direction: row;
        height: 50vh;
        .day-col {
            width: 15vw;
        }
        .shift-wrapper {
            position: relative;
            i {
                position: absolute;
                top: 5px;
                right: 10%;
                cursor: pointer;
            }
        }
        .shift-card {
            padding: 10px;
            width: 80%;
            background-color: #f0f0f0;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;
            span {
                font-weight: 500;
            }
        }
        button {
            display: flex;
            flex-direction: row;
            div {
                margin-left: 5px;
            }
        }
    }
    
}
.shift-workers {
    span {
        float: left;
        color: #555555;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        div {
            display: flex;
            align-items: center;
        }
        justify-content: space-between;
        img {
            width: 25px;
            height: 25px;
            border-radius: 100%;
            margin-right: 5px;
            object-fit: cover;
        }
        i {
            margin-left: 3px;
        }
    }
}

button {
    font-family: 'Montserrat', 'sans-serif';
    padding: 10px;
    font-weight: 600;
    background-color: white;
    border: none;
    &:focus {
        outline: none;
    }
    cursor: pointer;
}

.form {
    .sub-title {
        font-size: 1em;
    }
    span {
        font-size: 0.8em;
        font-weight: 600;
    }
    input, textarea {
        padding: 10px;
        width: 100px;
        margin-bottom: 10px;
        font-family: 'Montserrat', 'sans-serif';
        border: 1px solid lightgrey;
    }
    textarea {
        height: 100px;
    }
    button {
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        background-color: #1DB954;
    }
    #flash {
        font-size: 0.8em;
    }
}

#mananger-page-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    color: black;
    #workforce-panel {
        min-width: 220px;
        max-width: 220px;
        border-right: 1px solid $primary-color;
        height: 100vh;
        overflow: scroll;
        input {
            padding: 5px 10px;
            margin-left: 10px; 
            margin-bottom: 20px;
        }
        .home-btn {
            background-color: $primary-color;
            color: white;
            font-weight: 600;
            font-size: 1.3em;
            width: 100%;
            &:hover {
                background-color: #1DB954;
            }
        }
        div {
            display: flex;
            flex-direction: column;
        }
        button {
            // width: 350px;
            padding-left: 20px;
            cursor: pointer;
            text-align: left;
            &:hover {
                background-color: #f0f0f0;
            }
            span {
                font-weight: 600;
            }
            // display: flex;
            // justify-content: space-between;
            i {
                margin-right: 20px;
            }
            #bookmarked-star {
                color: #fbb116;
                font-size: 1.1em;
            }
        }
        #reserve-btn {
            width: 100%;
            padding: 15px;
            margin-bottom: 20px;
            background-color: #f0f0f0;
            &:hover {
                background-color: #1DB954;
                color: white;
            }
        }
    }   
    
}

#week-planner {
    display: flex;
    flex-direction: row;
    padding: 20px;
    overflow: scroll;
    background-color: #f0f0f0;
    .day-col {
        height: 95vh;
        min-width: 300px;
        max-width: 300px;
        margin-right: 10px;
        &:nth-child(7) {
            padding-right: 50vw;
        }
        p {
            font-weight: 500;
            padding: 0 20px;
        }
        
        .shift-card {
            cursor: pointer;
            padding: 10px;
            width: 80%;
            background-color: white;
            border: 3px solid white;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;
            span {
                font-weight: 500;
            }
        }
    }
}

#schedule-page-container {
    input {
        margin-left: 5vw;
        padding: 10px;
        width: 300px;
        &:focus {
            outline: none;
        }
    }
    #week-planner {
        margin: 0 5vw;
        background-color: black;
        overflow-y: hidden;
        overflow-x: scroll;
        .shift-card {
            background-color: #555555;
            border: none;
            color: white;
            
            span {
                color: white;
            }
        }
        .day-col {
            height: auto;
            &:last-child {
                padding-right: 0px !important;
            }
        }
    }
    .no-schedule-prompt {
        flex-direction: column;
        h3, span, a {
            color: $primary-color;
            font-weight: 700;
        }
    }
}
::-webkit-scrollbar {
    height: 10px;
    width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #888; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-color; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary-color; 
}

// ---------------------------------------------
// ------------- LADIES PAGE -------------------
// ---------------------------------------------

#ladies-page-container {
    padding-bottom: 100px;

    #landing-banner {
        position: relative;
        img {
            width: 100vw;
            height: 20vh;
            object-fit: cover;
            object-position: 40%; 
            opacity: 0.4;
        }
    
        h1 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 35%;
            font-weight: 400;
            font-size: 1.7em;
            span {
                font-weight: 700;
                color: $primary-color;
            }
        }
    }

    #page-details {
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        padding: 15px;
        .content-div {
            color: $para-color;
            margin-bottom: 10px;
            line-height: 1.5;
            p {
                font-family: $subheading;
                color: $primary-color;
                font-size: 2em;
                font-weight: 700;
                padding-left: 10px;
                border-left: 3px solid $primary-color;
            }
        }
        margin-bottom: 50px;
    }

    .lady-card-hover {
        div {
            padding-left: 5%;
            width: 90%;
        }
        img {
            opacity: 0.4;
        }
    }

}

.lady-card-hover {
    font-size: 0.9em;
}


.lady-card, .lady-card-hover {
    margin: 10px 0;
    width: 100vw;
    height: 500px;
    position: relative;
    cursor: pointer;
    img {
        width: 100vw;
        height: 500px;
        object-fit: cover;
    }
    .single-name {
        top: 45%;
    }
    div {
        position: absolute;
        top: 25%;
        width: 94%;
        padding: 0 3%;
        span {
            font-size: 2.2em;
            text-shadow: 0 0 20px #fff, 0 0 10px #fff;
        }
    }
    
}


// ---------------------------------------------
// --------------- RATE PAGE -------------------
// ---------------------------------------------


#rates-page-container {
    #landing-banner {
        position: relative;
        img {
            width: 100vw;
            height: 20vh;
            object-fit: cover;
            object-position: 50%; 
            opacity: 0.4;
        }

        h1 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 35%;
            font-weight: 400;
            font-size: 1.7em;
            span {
                font-weight: 700;
                color: $primary-color;
            }             
        }
    
        h3 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 35%;
            font-weight: 400;
            font-size: 1.7em;
            span {
                font-weight: 700;
                color: $primary-color;
            }             
        }
    }
    
    #service-details {
        font-size: 0.9em;
        padding: 15px;
        line-height: 1.2;
        color: $para-color;
        span {
            color: $primary-color;
            font-weight: 500;
        }
    }
    #rates-div {
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        h3 {
            font-family: $headings;
            font-size: 2em;
            width: 100vw;
            text-align: center;
            text-shadow: 0 0 20px #fff, 
                        0 0 10px #fff, 
                        0 0 50px #FF00B0, 
                        0 0 50px #FF00B0, 
                        0 0 40px #FF00B0;
        }
        #rates-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 50px;
            
            background-image: url("https://viper-images.s3-ap-southeast-2.amazonaws.com/blonde-brunette.jpg");
            background-attachment: fixed;
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
        }
        .rates-card {
            margin: 20px 0;
            padding: 20px 0;
            .deal-title {
                font-size: 1.3em;
                font-weight: 700;
            }
            text-align: center;
            width: 90vw;
            background-color: transparentize($color: #555555, $amount: 0.2);
            .deal-price {
                font-size: 3em;
                font-weight: 700;
                margin: 0;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                div {
                    color: $secondary-color;
                    font-size: 0.4em;
                    margin-right: 5px;
                    text-decoration: line-through;
                }
                span {
                    font-size: 0.5em;
                    padding-top: 5px;
                    padding-right: 5px;
                }
            }
            .inc-p {
                margin: 10px 0;
            }
            .feature-list {
                text-align: left;
                padding: 10px 20px;
                // font-size: 0.85em;
                i {
                    font-size: 1.2em;
                    margin-right: 10px;
                    color: $primary-color;
                }
            }
            &:hover {
                background-color: transparentize($color: black, $amount: .4)
            }
        }
        button {
            background-color: black;
            border-radius: 30px;
            color: $primary-color;
            font-weight: 600;
            font-size: 1em;
            padding: 15px 20px; 
            &:hover {
                background-color: $primary-color;
                color: white; 
            }
        }
    }
    
    #services-div {
        margin: 50px 0;
        #click-prompt {
            color: $primary-color;
            text-align: center;
            font-family: $subheading;
            font-weight: 700;
            font-size: 1.5em;
        }
        .flip-card {
            .react-card-flip {
                cursor: default;
            }
            img {
                width: 100vw;
                height: 40vh;
                object-fit: cover;

            }
            cursor: none;
            .front-card, .back-card {
                position: relative;
                p {
                    width: 80vw;
                    text-align: center;
                    font-weight: 700;
                    position: absolute;
                    top: 40%;
                    padding: 0 10vw;
                    text-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
					            0 6.7px 5.3px rgba(0, 0, 0, 0.048);
                }
            }
            .back-card {
                img {
                    opacity: 0.4;
                }
            }
        }
    }

    #book-btn {
        width: 100vw;
        margin: 50px 0;
        text-align: center;
        button {
            box-shadow: 0 0 3px #fff, 
            0 0 10px #fff, 
            0 0 50px #FF00B0;
        }
    }
}


// ---------------------------------------------
// ------------ DISCRETION PAGE ----------------
// ---------------------------------------------

#discretion-page-container {

    #landing-banner {
        position: relative;
        img {
            width: 100vw;
            height: 20vh;
            object-fit: cover;
            opacity: 0.7;
        }
        h1 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 33%;
            font-weight: 400;
            font-size: 1.7em;
            span {
                font-weight: 700;
                color: $primary-color;
            }               
        }
    }

    #disc-content {
        padding: 5vh 15px;
        color: $para-color;
        line-height: 1.5;
        span {
            font-weight: 600;
            color: $primary-color;
        }
        p {
            font-weight: 700;
            a {
                color: $primary-color;
            }
        }
    }
    #customer-reviews {

        background-image: url("https://viper-images.s3-ap-southeast-2.amazonaws.com/QVJG4680.jpg");
        background-attachment: fixed;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        border-top: 1px solid #f0f0f0;

        h3 {
            font-family: $subheading;
            color: $primary-color;
            // width: 100vw;
            text-align: center;
            font-size: 2em;
            font-weight: 600;
            padding: 0 10vw;
            margin: 10px 0;
        }
        
        #review-item {
            padding: 15px;
            color: $para-color;
            font-style: italic;
            text-align: center;
            font-weight: 700;
            span {
                color: $secondary-color;
                font-weight: 600;
            }
        }

    }
}


// ---------------------------------------------
// -------------- SCHEDULE PAGE ----------------
// ---------------------------------------------

#schedule-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    input {
        width: 82vw;
        background-color: black;
        border: 2px solid $primary-color;
        color: white;
        font-weight: 600;
        ::placeholder {
            color: white;
        }
    }
    #hover-display {
        position: absolute;
        img {
            width: 300px;
            height: 450px;
            object-fit: cover;
        }
        span {
            background-color: transparentize($color: black, $amount: 0.8);
            position: absolute;
            width: 100%;
            text-align: center;
            top: 10%;
            left: 0;
            line-height: 1.9;
            span {
                background-color: transparent;
                font-weight: 600;
                font-size: 2em;
            }
        }
        i {
            position: absolute ;
            top: 3%;
            left: 5%;
            font-size: 2em;
        }
    }
}

#sched-btm-text {
    p {
        padding: 15px;
    }
    a {
        color: $primary-color;
        font-weight: 700;
    }
}


// ---------------------------------------------
// ------------------ TOUR PAGE ----------------
// ---------------------------------------------


#tour-page-container {
    #landing-banner {
        position: relative;
        img {
            width: 100vw;
            height: 20vh;
            object-fit: cover;
            opacity: 0.4;
        }
        h1 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 33%;
            font-weight: 400;
            font-size: 1.7em;
            span {
                font-weight: 700;
                color: $primary-color;
            }                   
        }
    }

    #tour-desc {
        padding: 15px;
        color: $para-color;
        line-height: 1.5;
        span {
            color: $primary-color;
            font-weight: 600;
        }
    }

    #album-wrapper {
        display: flex;
        flex-wrap: wrap;
       
        .tp-album-wrapper {
            margin: 20px 0;
            padding-top: 20px;
            border-top: 1px solid lightgrey;
        }
        .tp-album-cover {
            img {
                width: 100vw;
                height: 150px;
                object-position: 5% 10%;
                object-fit: cover;
                // padding: 0 25vw;
            }
        }
        img, .image-gallery {
            max-width: 100vw;
        }
    }
}


// ---------------------------------------------
// -------------- ALBUM MANAGER ----------------
// ---------------------------------------------

.alt-text-input {
    padding: 10px 15px;
    border: 1px solid lightgrey;
    font-family: 'Montserrat', 'sans-serif';
    &:focus {
        outline: none;
    }
    width: 500px;
    margin-bottom: 20px;
}

#album-mananger {
    display: flex;
    #upload-div {
        margin: 10px 0;
    }
    #ab-left {
        padding-right: 50px;
        margin-right: 50px;
        border-right: 1px solid $primary-color;
    }
    #ab-right {
        padding-top: 20px;
        button {
            background-color: #1DB954;
            color: white;
            border-radius: 5px;
            margin-bottom: 10px;
        }
    }
    #ab-list {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $primary-color;
        margin-top: 20px;
        button {
            padding: 20px;
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
    .form {
        input {
            width: 200px;
        }
    }
    #album-list {
        border-top: 1px solid $primary-color;
        padding: 15px 0;
        display: flex;
        flex-wrap: wrap;
        .photo-wrapper {
            position: relative;
            width: 220px;
            height: 220px;
            margin: 10px;
            padding-bottom: 15px;
            input {
                font-size: 0.8em;
                padding: 5px;
            }
        }
        #alt-edit {
            font-size: 0.8em;
            font-weight: 600;
            color: #1DB954;
            cursor: pointer;
        }
        i {
            position: absolute;
            top: -8px;
            right: -8px;
            color: $primary-color;
            background-color: white;
            border-radius: 100%;
            overflow: hidden;
            cursor: pointer;
            font-size: 1.2em;
        }
        img {
            width: 220px;
            height: 220px;
            object-fit: cover;
        }
    }
}


// ---------------------------------------------
// --------------- BLOG MANAGER ----------------
// ---------------------------------------------

#blog-mananger {
    display: flex;
    #upload-div {
        margin: 10px 0;
        .alt-text-input {
            width: 100%;
        }
    }
    #bm-left {
        padding-right: 50px;
        border-right: 1px solid $primary-color;
    }
    #bm-right {
        padding-left: 50px;
        margin-top: 5vh;
        .blog-item {
            display: flex;
            padding: 20px 0;
            border-bottom: 1px solid $primary-color;
            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                margin-right: 10px;
            }

            .bi-date {
                font-size: 0.8em;
                font-weight: 700;
            }
            .bi-title {
                font-size: 1.1em;
                font-weight: 500;
            }
            button {
                margin-top: 10px;
                background-color: #9c2121;
                border-radius: 5px;
                color: white;
            }
            #alt-edit {
                font-size: 0.8em;
                font-weight: 600;
                color: #1DB954;
                cursor: pointer;
            }
            #alt-edit-input {
                padding: 5px;
                width: 300px;
            }
        }
    }
}

#privacy-page {
    padding: 15px;
    h3 {
        font-size: 2em;
    }
    line-height: 1.5;
    span {
        font-weight: 700;
    }
}

#single-blog-container {
    #sb-blog-wrapper {
        padding: 5vh 0;
    }
    #sb-nav {
        display: flex;
        justify-content: space-between;
        button {
            i {
                font-size: 2em;
            }
            background-color: black;
            color: $primary-color;
            span {
                color: white;
            }
        }
    }
    .loading-gif {
        width: 30px;
        height: 30px;
    }
    #sb-top {
        padding: 15px;
        h3 {
            padding-top: 10px;
            border-top: 1px solid $primary-color;
            font-size: 2em;
            margin: 0;
        }
        #sb-blog-date {
            font-size: 0.7em;
            font-weight: 700;
        }
        p {
            color: $para-color;
        }
        a {
            color: $primary-color;
            font-weight: 600;
        }
    }
    #doesnt-exist {
        display: flex;
        width: 100vw;
        height: 20vh;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        font-weight: 600;
    }
    img {
        width: 100vw;
        height: auto;
    }
}


// ---------------------------------------------
// --------------- BLOG PAGE -------------------
// ---------------------------------------------

#blog-page-container {
    #landing-banner {
        position: relative;
        img {
            width: 100vw;
            height: 20vh;
            object-fit: cover;
            object-position: 10% 90%;
            opacity: 0.4;
        }
        h1 {
            width: 100vw;
            text-align: center;
            margin: 0;
            position: absolute;
            top: 35%;
            font-weight: 400;
            font-size: 1.7em;
            span {
                font-weight: 700;
                color: $primary-color;
            }               
        }
    }
    #bp-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .loading-gif {
            width: 50px;
            height: 50px;
        }
        .bp-list-bi {
            margin-bottom: 50px;
            border: 1px solid #555555;
            h2 {
                margin: 0;
            }
            span {
                font-size: 0.6em;
                font-weight: 700;
            }
            a {
                background-color: black;
                color: $primary-color;
                font-weight: 600;
            }
        }
        img {
            width: 100vw;
            height: auto;
        }
        .bp-list-bi-top {
            padding: 15px;
        }
    }
    #blog-welcome {
        padding: 15px;
    }
}



// ---------------------------------------------
// ---------------- IPAD SIZING ----------------
// ---------------------------------------------

@media screen and (min-width: 700px) {

    #sched-btm-text {
        padding: 0 10vw;
    }

    #landing-banner {
        display: flex;
        justify-content: center;
        img {
            object-fit: cover;
            opacity: 0.4;
            object-position: 10% 40%; 
            height: 70vh;
        }
        h3 {
            width: 60vw;
            font-size: 5em;
        }
    }

    #privacy-page {
        padding: 5vh 20vw;
    }

    // landing page adjustments
    #contact-header {
        #contact-icon {
            margin: 10px 0;
            font-weight: 700;
            font-size: 1.2em;
        }
        #viper-logo {
            margin: 20px 0;
            width: 40vw;
        }
        #landing-nav-bar {
            margin: 20px 0;
        }
    }
    #landing-page-container {

        #landing-banner {
            img {
                height: 40vh;
                object-position: 5% 40%;
            }
            h1 {
                font-size: 3em;
            }
        }
    
        #landing-div-1 {
            width: 100vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            padding: 50px 0;
            p {
                width: 400px;
                font-weight: 500;
                line-height: 1.5;
            }
            img {
                width: 300px;
                height: auto;
            }
            #legal-age-prompt {
                left: -420px;
            }
        }

        #option-bar-1 {
            justify-content: center;
            align-items: center;
            .bar-option-card {
                width: 300px;
                margin: 10px;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                }
            }
        }

        .content-wrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .content-sec {
                width: 30vw;
                text-align: center;
                margin: 0 2vw;
            }
            img {
                width: 350px;
                height: 350px;
                object-fit: cover;
                object-position: 50% 0%;
            }
        }

        #todays-list {

            margin: 50px 0;
    
            .content-sec {
                padding: 20px 10vw;
            }

            #tl-display {
                background-color: transparentize($color: grey, $amount: 0.8);
                display: flex;
                flex-direction: row;
                padding: 1vw;
                .lady-card, .lady-card-hover {
                    height:480px;
                    width: 350px;
                    margin: 0 10px;
                    img {
                        width: 350px;
                        height:480px;
                    }
                }
                .single-name {
                    top: 45%;
                }
                
            }
        }

        #reason-div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 50px 0;
            .reason-card {
                width: 50vw;
                span {
                    font-size: 1.5em;
                }
            }
        }

        #background-slider-container {
            background-position: center;
        }
        
        #testimony-div {
            
            justify-content: center;
            #main-test {
                padding: 7vh 10vw !important;
                font-size: 3em;
            }
            .test-card {
                padding-bottom: 50px;
                font-size: 1.2em;
            }
        }

        #open-24 {
            background-color: black;
        }

        #visit-div {
            // width: 100vw;
            display: flex;
            justify-content: center;
            align-items: center;
            #map-display {
                iframe {
                    width: 300px;
                    height: 300px;
                    margin: 0 5vw;
                }
            }
            a {
                color: $secondary-color;
            }
        }

        #footer-graphic {
            #back-img-footer {
                height: 90vh;
            }
            #logo-footer {
                img {
                    width: 80vw;
                    height: auto
                }
            }
            margin-bottom: 50px;
        }
    }

    #page-footer {
        justify-content: space-evenly;
        font-size: 1em;
        #footer-left {
            width: 500px;
        }
        padding-left: 300px;
       
    }
    #sb-top {
        padding: 15px 0 !important;
    }

    // ladies page adjustments 
    #ladies-page-container {
        #landing-banner {
            img {
                height: 40vh;
                width: 100vw;
                object-fit: cover;
                object-position: 10% 30%; 
            }
            h3 {
                font-size: 4em;
            }
        }
        #page-details {
            align-items: center;
            margin: 20px 0;
            .content-div {
                width: 85vw;
            }
        }
        .lady-card, .lady-card-hover { 
            width: 400px !important;
            margin: 10px;
            height: 450px;
            img { 
                height: 450px;
                width: 400px !important;
            }
            div {
                top: 15%;
                left: 10%;
                width: 80%;
                line-height: 1.5;
            }
        }
        .lady-card-hover {
            img {
                opacity: 0.4;
            }
        }
        #ladies-list {
            margin: 50px 0;
            width: 100vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    #rates-page-container {
        #rates-div {
            h3 {
                font-size: 3em;
            }
        }
        #landing-banner {
            img {
                width: 100vw;
                opacity: 0.4;
                height: 40vh;
                object-position: 40% 100%; 
            }
            h3 {
                font-size: 4em;
            }
        }
        #service-details {
            padding: 5vh 5vw;
            font-size: 1em;
            line-height: 1.2;
        }
        #rates-list {
            padding: 50px 0vw;
            .rates-card {
                margin: 10px;
                padding: 30px 0;
                width: 350px;
            }
        }
        #card-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .flip-card {
                cursor: pointer;
                width: 350px;
                margin: 10px;
                img, p, .front-card, .back-card {
                    width: 350px !important;
                    padding: 0 !important;
                }
            }
        }
        #book-btm {
            margin: 50px 46vw;
        }
    }
    
    #discretion-page-container {
        #landing-banner {
            img {
                width: 100vw;
                opacity: 0.8;
                height: 40vh;
                object-position: 10% 55%; 
            }
            h3 {
                top: 40%;
                font-size: 4em;
            }
        }
        #disc-content {
            padding: 2vh 10vw;
            font-size: 1.1em;
            line-height: 1.5;
            a {
                cursor: pointer;
            }
        }
        #customer-reviews {
            padding: 5vh 20vw;
            #review-item {
                margin: 50px 0;
                padding: 0 2vw;
            }
        }
    }

    #schedule-wrapper {
        justify-content: center;
        input {
            font-size: 1em;
            padding: 15px 20px;
            width: 40vw;
        }
    }

    #blog-div {
        background-color: white;
        h3 {
            font-size: 3em;
            margin: 0;
        }
        #prompt-text {
            width: 100vw;
            margin-bottom: 50px;
        }
        #blog-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 0 10vw;
            .blog-item {
                margin: 10px;
                width: 300px;
                img {
                    width: 300px;
                }
            }
        }
    }

    #tour-page-container {

        #landing-banner {
            img {
                width: 100vw;
                opacity: 0.8;
                height: 40vh;
                object-position: 10% 60%; 
                opacity: 0.4;
            }
            h3 {
                top: 40%;
                font-size: 6em;
            }
        }
        #tour-desc {
            padding: 2vh 10vw;
        }
        #album-wrapper {
            .tp-album-wrapper {
                display: flex;
                .tp-album-cover {
                    img {
                        width: 300px;
                        height: 300px;
                        object-position: 40% 10%;
                    }
                    margin-right: 10px;
                }
            }
        }
    }

    #single-blog-container {
        #sb-blog-wrapper {
            display: flex;
            justify-content: center;
            padding: 5vh 10vw;
        }
        .loading-gif {
            width: 30px;
            height: 30px;
        }
        img {
            height: auto;
            width: auto;
            max-width: 80vw;
        }
    }

    #blog-page-container {
        #landing-banner {
            img {
                height: 40vh;
                object-position: 10% 60%;
            }
            h3 {
                font-size: 3em;               
            }
        }
        #bp-list {
            margin-bottom: 50px;
            display: flex;
            flex-wrap: wrap;
            font-size: 0.8em;
            .bp-list-bi {
                margin: 10px;
                width: 330px;
            }
            img {
                width: 100%;
                height: 330px;
                object-fit: cover;
            }
            .bp-list-bi-top {
                padding: 15px;
            }
        }
        #blog-welcome {
            padding: 50px 30vw;
            text-align: center;
        }
    }

    #profile-details-wrapper {
        #profile-desc {
            padding: 0 10vw;
        }
        #photo-carosel {
            padding: 20px 0;
            border-top: 1px solid lightgrey;
            border-bottom: 1px solid lightgrey;
            img {
                max-height: 60vh;
            }
        }
    }
}

// ---------------------------------------------
// ------------- DESKTOP SIZING ----------------
// ---------------------------------------------

@media screen and (min-width: 1200px) {

    #landing-banner {    
        h1 {
            font-size: 4em !important;
        }
    }

    #landing-page-container {

        .content-wrapper {
            img {
                width: 450px;
                height: 450px;
            }
        }

        #todays-list {
            .content-sec {
                padding: 50px 20vw;
            }
        }

        #option-bar-1 {
            .bar-option-card {
                width: auto;
                margin: 10px;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                }
            }
        }

        #testimony-div {
            #main-test {
                padding: 0 20vw;
            }
        }

        #visit-div {
            #map-display {
                iframe {
                    width: 400px;
                    height: 400px;
                }
            }
        }

        #footer-graphic {

            #logo-footer {
                img {
                    width: 50vw;
                    height: auto
                }
            }
        }

    }

    #page-footer {
        padding-left: 0;
    }
}


// ---------------------------------------------
// ------------- DESKTOP EXTRAS ----------------
// ---------------------------------------------

@media screen and (min-width: 1600px) {

    #option-bar-1 {
        padding: 0 10vw;
    }

    #blog-list {
        padding: 0 20vw !important; 
    }

    #reason-div {
        .reason-card {
            width: 30vw !important;
        }
    }

    #ladies-list {
        width: 80vw !important;
        padding-left: 10vw !important;
    }

    #rates-list, #card-list {
        padding: 20px 15vw !important;
    }

    #media-list {
        img {
            width: 260px !important;
            height: 260px !important;
        }
    }

    #bp-list {
        font-size: 1em !important;
        .bp-list-bi-top {
            padding: 15px;
        }
    }

    #page-details {
        .content-div {
            width: 65vw !important;
        }
    }
    #blog-page-container {
        #bp-list {
            padding: 0 5vw;
        }
    }
   
}